@import 'codemirror/lib/codemirror.css';
@import 'codemirror/theme/monokai.css';
@import 'codemirror/theme/xq-light.css';

/**
  * CodeEditor
  */
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.text-remove {
  animation-name: fadeOut;
  /* animation-duration: 0.2s; */
  /* animation-delay: 2s; */
  animation-timing-function: linear;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  user-select: none;
  cursor: default;
}

.codePair-cursor {
  position: absolute;
  border-left-style: solid;
  border-left-width: 2.5px;
  padding: 0px;

  &[data-pos='top'] .codePair-name {
    bottom: calc(0% - 10px) !important;
  }
}

.codePair-name {
  white-space: nowrap;
  position: absolute;
  display: inline-block;
  left: -2px;
  padding: 1px 4px;
  border-radius: 2px 2px 2px 0px;
  bottom: 100%;
}

.EasyMDEContainer {
  .editor-toolbar {
    border: none;
  }

  .CodeMirror {
    border-bottom: none;
  }

  /* 
   * Make vim command visible.
   * issue ref https://github.com/yorkie-team/codepair/issues/148  
   */
  .CodeMirror-dialog {
    &.CodeMirror-dialog-bottom {
      position: absolute;
      z-index: 2;
      bottom: 10px;
    }
  }

  .cm-s-easymde .cm-header-1 {
    font-size: 28px;
    line-height: 60px;
  }
  .cm-s-easymde .cm-header-2 {
    font-size: 21px;
    line-height: 50px;
  }
  .cm-s-easymde .cm-header-3 {
    font-size: 18px;
    line-height: 43px;
  }
  .cm-s-easymde .cm-header-4 {
    font-size: 14px;
    line-height: 40px;
  }
  .cm-s-easymde .cm-header-5 {
    font-size: 12px;
    line-height: 36px;
  }
  .cm-s-easymde .cm-header-6 {
    font-size: 10px;
    line-height: 34px;
  }

  .CodeMirror-line span.cm-keyword {
    color: #708;
  }
  .CodeMirror-line span.cm-atom {
    color: #219;
  }
  .CodeMirror-line span.cm-number {
    color: #164;
  }
  .CodeMirror-line span.cm-def {
    color: #00f;
  }
  .CodeMirror-line span.cm-variable {
    color: black;
  }
  .CodeMirror-line span.cm-property {
    color: black;
  }
  .CodeMirror-line span.cm-operator {
    color: black;
  }
  .CodeMirror-line span.cm-string {
    color: #a11;
  }
  .CodeMirror-line span.cm-string-2 {
    color: #f50;
  }
  .CodeMirror-line span.cm-meta {
    color: #555;
  }
  .CodeMirror-line span.cm-error {
    color: #f00;
  }
  .CodeMirror-line span.cm-qualifier {
    color: #555;
  }
  .CodeMirror-line span.cm-builtin {
    color: #30a;
  }
  .CodeMirror-line span.cm-bracket {
    color: #997;
  }
  .CodeMirror-line span.cm-tag {
    color: #170;
  }
  .CodeMirror-line span.cm-attribute {
    color: #00c;
  }
  .CodeMirror-line span.cm-hr {
    color: #999;
  }
}

.editor-preview-side .marpit svg foreignObject section {
  border: none;
}
.editor-preview-full .marpit svg foreignObject section {
  border: none;
}

.editor-preview-side .marpit a {
  color: white;
}

.editor-preview-full .marpit a {
  color: white;
}

/**
 * DrawingBoard
 */
.canvas-touch-none {
  touch-action: none;
}

/* mouse css */
.auto {
  cursor: auto;
}
.default {
  cursor: default;
}
.crosshair {
  cursor: crosshair;
}

.eraser {
  cursor: url('./assets/icons/eraser.cur'), auto;
}

.canvas {
  position: absolute;
}

.canvas-upper {
  z-index: 1;
  top: 0px;
  left: 0px;
}

.canvas-lower {
  z-index: 0;
  top: 0px;
  left: 0px;
}

.fullscreen button.table-of-contents {
  display: none;
}

#tableOfContents {
  position: absolute;
  top: 112px;
  right: 46px;
  bottom: 0px;
  width: 300px;
  flex: 0 0 auto;
  background-color: #fafafb;
  border: 1px solid #e0e0e0;
  border-bottom: none;
  overflow: auto;
}

[data-theme='dark'] {
  #tableOfContents {
    background-color: #555555;
    border: 1px solid #303030;

    ul > li a {
      color: #fff !important;
      &:hover {
        text-shadow: 0px 0px 10px black;
      }
    }
  }
}
